export const contract_abi_url =
  "https://api-sepolia.etherscan.io/api?module=contract&action=getabi&address=";

export const DECIMAL_18 = 1000000000000000000;

// export const SEVEN_DAY = 604800;
// export const TWO_DAY = 172800;
export const SEVEN_DAY = 600;
export const TWO_DAY = 300;

export const A_DAY = 0;
export const A_WEEK = 1;
export const A_MONTH = 2;
export const A_YEAR = 3;
